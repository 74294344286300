import React from "react";
import { Card, Image, Button, Reveal, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import Spinner from "../../common/Spinner";

class PublicationItem extends React.Component {
  state = {
    allData: [],
    mailerData: [],
    loading: false,
    showModal: false,
    showAllModal: false,
    showUploadedModal: false,
  };

  // downloadAllData = (id) => {
  //   this.setState({ showAllModal: true });
  //   setTimeout(() => {
  //     axios
  //       .get(`/api/subscriber/all/${id}`)
  //       .then((res) => {
  //         if (res.data.length > 0) {
  //           this.setState({ allData: res.data });
  //         }
  //       })
  //       .then((res) => {
  //         let btn = this.refs.all;
  //         btn.link.click();
  //         this.setState({ allData: [] });
  //       })
  //       .catch((err) => console.log(err));
  //   }, 100);
  // };

  // downloadImportedData = (id) => {
  //   this.setState({ showUploadedModal: true });
  //   setTimeout(() => {
  //     axios
  //       .get(`/api/subscriber/all/${id}?uploaded`)
  //       .then((res) => {
  //         if (res.data.length > 0) {
  //           this.setState({ allData: res.data });
  //         }
  //       })
  //       .then((res) => {
  //         let btn = this.refs.all;
  //         btn.link.click();
  //         this.setState({ allData: [] });
  //       })
  //       .catch((err) => console.log(err));
  //   }, 100);
  // }


  // downloadMailerData = (id) => {
  //   this.setState({ showModal: true });
  //   setTimeout(() => {
  //     axios
  //       .get(`/api/subscriber/mailer/${id}`)
  //       .then((res) => {
  //         if (res.data.length > 0) {
  //           this.setState({ mailerData: res.data });
  //         }
  //       })
  //       .then((res) => {
  //         let btn = this.refs.mailer;
  //         btn.link.click();
  //         this.setState({ mailerData: [] });
  //       })
  //       .catch((err) => console.log(err));
  //   }, 100);
  // };

  downloadMailerData = (id, options = { target: ''}) => {
    this.setState({ showModal: true });
    setTimeout(() => {
      axios
        .get(`/api/subscriber/v2/mailer/${id}`)
        .then((res) => {
          if (res.data.length > 0) {

            const today = new Date();
            const month = today.getMonth() + 1;
            const filename =
              this.props.publication.publicationName +
              "_" +
              today.getDate() +
              month +
              today.getFullYear() +
              ".csv";

            // Download CSV
            const link = document.createElement('a')
            link.href = 'data:text/csv,' + encodeURIComponent(res.data)
            link.download = filename
            link.click()
            this.setState({ showModal: false });
          }

        })
        .catch((err) => console.log(err));
    }, 100);

  }

  doneDownloadingAll = () => {
    this.setState({ showAllModal: false, showUploadedModal: false });
  };

  doneDownloading = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { userType, publication } = this.props;
    let displayContent;
    let displayModal;
    const today = new Date();
    const month = today.getMonth() + 1;

    const filename =
      publication.publicationName +
      "_" +
      today.getDate() +
      month +
      today.getFullYear() +
      ".csv";

    if (this.state.showModal) {
      displayModal = (
        <Modal
          dimmer={true}
          open={true}
          closeOnDimmerClick={false}
          onClose={this.close}
        >
          <Modal.Header>Downloading Current Mailing List</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Your download will appear in your browser downloads
              <Spinner />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      );
    } else if (this.state.showAllModal || this.state.showUploadedModal) {
      let header = ""
      if( this.state.showAllModal ) header = "Downloading Entire Database"
      if( this.state.showUploadedModal ) header = "Downloading Uploded User Database"
      displayModal = (
        <Modal
          dimmer={true}
          open={true}
          closeOnDimmerClick={false}
          onClose={this.close}
        >
          <Modal.Header>{header}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Your download will appear in your browser downloads
              <Spinner />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      );
    } else {
      displayModal = null;
    }
    
    switch (userType) {
      case "mailer":
        displayContent = (
          <Card>
            <Reveal
              style={{ margin: "10px", padding: "0px" }}
              animated="move"
              key={publication.publicationName}
            >
              <Reveal.Content visible>
                <div style={{ height: "360px", backgroundColor: "#fff" }}>
                  <Image
                    src={publication.thumbImageName}
                    style={{ margin: "0px" }}
                    size="massive"
                  />
                </div>
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card.Content>
                  <Card.Description>
                    <Button
                      type="button"
                      fluid
                      style={{ marginBottom: "20px" }}
                      onClick={() => {
                        this.downloadMailerData(publication._id);
                      }}
                    >
                      Download Current Mailing File
                    </Button>
                    <CSVLink
                      ref="mailer"
                      style={{ display: "none" }}
                      filename={filename}
                      asyncOnClick={true}
                      data={this.state.mailerData}
                      onClick={(event, done) => {
                        done(this.doneDownloading());
                      }}
                    />
                  </Card.Description>
                </Card.Content>
              </Reveal.Content>
            </Reveal>
          </Card>
        );
        break;
      case "admin":
        displayContent = (
          <Card>
            <Reveal
              style={{ margin: "10px", padding: "0px" }}
              animated="move"
              key={publication.publicationName}
            >
              <Reveal.Content visible>
                <div style={{ height: "360px", backgroundColor: "#fff" }}>
                  <Image
                    src={publication.thumbImageName}
                    style={{ margin: "0px" }}
                    size="massive"
                  />
                </div>
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card.Content>
                  <Card.Description>
                    <Link to={`/addreader/${publication._id}`}>
                      <Button fluid>Add Reader</Button>
                    </Link>{" "}
                    <br />
                    <Link to={`/searchreaders/${publication._id}`}>
                      <Button fluid>Search Readers</Button>
                    </Link>
                  </Card.Description>
                </Card.Content>
              </Reveal.Content>
            </Reveal>
          </Card>
        );
        break;
      default:
        displayContent = (
          <Card>
            <Reveal
              style={{ margin: "10px", padding: "0px" }}
              animated="move"
              key={publication.publicationName}
            >
              <Reveal.Content visible>
                <div style={{ height: "360px", backgroundColor: "#fff" }}>
                  <Image
                    src={publication.thumbImageName}
                    style={{ margin: "0px" }}
                    size="massive"
                  />
                </div>
              </Reveal.Content>
              <Reveal.Content hidden>
                <Card.Content>
                  <Card.Description>
                    <Button
                      type="button"
                      fluid
                      style={{ marginBottom: "20px" }}
                      onClick={() => {
                        this.downloadMailerData(publication._id);
                      }}
                    >
                      Download Current Mailing File
                    </Button>
                    <CSVLink
                      ref="mailer"
                      style={{ display: "none" }}
                      filename={filename}
                      asyncOnClick={true}
                      data={this.state.mailerData}
                      onClick={(event, done) => {
                        done(this.doneDownloading());
                      }}
                    />
                    <Button
                      type="button"
                      fluid
                      style={{ marginBottom: "20px" }}
                      onClick={() => {
                        this.downloadAllData(publication._id);
                      }}
                    >
                      Download Entire Database
                    </Button>
                    {/* <Button
                      type="button"
                      fluid
                      style={{ marginBottom: "20px" }}
                      onClick={() => {
                        this.downloadImportedData(publication._id);
                      }}
                    >
                      Download Imported Users
                    </Button> */}
                    <CSVLink
                      ref="all"
                      style={{ display: "none" }}
                      filename={filename}
                      asyncOnClick={true}
                      data={this.state.allData}
                      onClick={(event, done) => {
                        done(this.doneDownloadingAll());
                      }}
                    />
                    <Link to={`/addreader/${publication._id}`}>
                      <Button fluid>Add Reader</Button>
                    </Link>{" "}
                    <br />
                    <Link to={`/searchreaders/${publication._id}`}>
                      <Button fluid>Search Readers</Button>
                    </Link>
                  </Card.Description>
                </Card.Content>
              </Reveal.Content>
            </Reveal>
          </Card>
        );
        break;
    }

    return (
      <React.Fragment>
        {displayContent}
        {displayModal}
      </React.Fragment>
    );
  }
}

export default PublicationItem;
